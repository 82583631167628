<template>
  <div>   
    <br>
    
     <b-row class="justify-content-center">
        <b-col cols="11" md="9" class="text-center">
            <center>
                <v-tabs  v-model="tab" fixed-tabs  background-color="transparent" dark >
                    <v-tab style="font-size:17px;">
                       GERENTE
                    </v-tab>
                    <v-tab style="font-size:17px;"> 
                       Callcenter
                    </v-tab>
                </v-tabs>
            </center>
            <br><br>
            <center v-if="loader == true">
                <SpinnerLoader/>
                <p  style="color:#fff;">Procesando información, no cierres el navegador <br> espera un momento...</p>
            </center>
            <br>
            <template v-if="tab == 0">
                <template>
                    <img src="https://inquebrantables2022.com.mx/campana/prepago.png" class="rounded" alt="Image" :style="!isMobile ? 'width:20%;' : 'width:50%;'">  
                    <b-card  style="background-color:#002860; border: 4px solid #368eff; border-radius: 10px; " class="mt-3">
                        <br v-if="!isMobile">
                        <b-card-text class="text-center">
                             <div class="table-responsive">
                                <table class="table table-borderless mt-3 text-center">
                                    <thead class="text-white">
                                    <tr>
                                        <td>LUGAR</td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td> 
                                        <td v-if="isMobile">GERENTE</td> 
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td><template v-if="!isMobile">GERENTE</template></td>
                                        <td>CALLCENTER</td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile">CUMPLIMIENTO</td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td><template v-if="!isMobile">CUMPLIMIENTO</template></td>
                                        <!-- <td>DESAFÍOS</td> -->
                                        <td>PUNTOS</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <br>
                                    <tr v-for="(ran, index) in arrayPrepago" :key="ran.id" >
                                        <td>
                                            <img v-if="index < 3" :src="'https://inquebrantables2022.com.mx/medalla/'+ index + '.png'" class="rounded" alt="Image" :style="!isMobile ? 'width:20%; margin-top:-8px;' : 'width:80%; margin-top:2px;'">   
                                            <br v-if="index < 3"> 
                                            <h4 v-if="index > 2" style="color: #01aae0 ;" class="mt-1">
                                            {{index + 1  }}
                                            </h4>  
                                            <br>
                                        </td>
                                        <td :colspan="isMobile ? '10' : ''">
                                            <div :style=" index < 3 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;' 
                                            : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                                 <b style=" font-size:13px; ">
                                                    {{ran.gerente}} 
                                                </b>
                                            </div>  
                                        </td>
                                        <td>
                                            <div :style=" index < 3 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;'
                                            : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                                 <b style=" font-size:13px; ">
                                                    {{ran.callcenter}}
                                                </b>
                                            </div>  
                                        </td>
                                        <td :colspan="isMobile ? '16' : ''"> 
                                                          <div :style="index < 3 ? 'color: #83c32d ;' : 'color: #01aae0 ;'">
                                            <b style="font-size:12px;">
                                                <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-22px;' : 'margin-top:-20px'">
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Mayo</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Mayo</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                           {{ran.mayo}} 
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Junio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Junio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.junio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Julio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Julio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.julio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Agosto</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Agosto</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.agosto}}
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b>
                                        </div> 
                                            <!-- <h4 style="color:#fff;">
                                                <template v-if="ran.cumplimiento">  <template v-if="ran.cumplimiento"> {{parseInt(ran.cumplimiento.mayo) + parseInt(ran.cumplimiento.junio)}}</template>
                                                <template v-else>0</template></template>
                                                <template v-else>0</template>
                                            </h4> -->
                                            <!-- <br> -->
                                        </td>
                                        <!-- <td>
                                            <h4 style="color:#fff;" class="mt-1">
                                                {{ran.puntos}}
                                            </h4>
                                        </td> -->
                                        <td>
                                            <div :style=" index < 3 ? 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0; margin-top:-4px;'
                                            : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0; margin-top:-4px' " :class="isMobile ? 'flexbox2' : 'flexbox'">
                                                <b style="font-size:26px;">
                                                    {{ran.total}}
                                                </b>
                                            </div>  
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </b-card-text>
                        <br v-if="!isMobile">
                    </b-card>
                    <hr><br>
                </template>
                <template>
                    <img src="https://inquebrantables2022.com.mx/campana/pospago.png" class="rounded" alt="Image" :style="!isMobile ? 'width:20%;' : 'width:50%;'">  
                    <b-card  style="background-color:#002860; border: 4px solid #368eff; border-radius: 10px; " class="mt-3">
                        <br v-if="!isMobile">
                        <b-card-text class="text-center">
                             <div class="table-responsive">
                                <table class="table table-borderless mt-3 text-center">
                                    <thead class="text-white">
                                    <tr>
                                           <td>LUGAR</td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td> 
                                        <td v-if="isMobile">GERENTE</td> 
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td><template v-if="!isMobile">GERENTE</template></td>
                                        <td>CALLCENTER</td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile">CUMPLIMIENTO</td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td><template v-if="!isMobile">CUMPLIMIENTO</template></td>
                                        <!-- <td>DESAFÍOS</td> -->
                                        <td>PUNTOS</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <br>
                                    <tr  v-for="(ran, index) in arrayPospago" :key="ran.id" >
                                        <td>
                                            <img v-if="index < 3" :src="'https://inquebrantables2022.com.mx/medalla/'+ index + '.png'" class="rounded" alt="Image" :style="!isMobile ? 'width:20%; margin-top:-8px;' : 'width:80%; margin-top:2px;'"> 
                                          
                                             <br v-if="index < 3"> 
                                            <h4 v-if="index > 2" style="color: #01aae0 ;" class="mt-1">
                                            {{index + 1  }}
                                            </h4>  
                                            <br>
                                        </td>
                                        <td :colspan="isMobile ? '10' : ''">
                                            <div :style=" index < 3 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;' 
                                            : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                                 <b style=" font-size:13px; ">
                                                    {{ran.gerente}} 
                                                </b>
                                            </div>  
                                        </td>
                                        <td>
                                            <div :style=" index < 3 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;'
                                            : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                                 <b style=" font-size:13px; ">
                                                    {{ran.callcenter}}
                                                </b>
                                            </div>  
                                        </td>
                                        <td :colspan="isMobile ? '16' : ''">
                                                          <div :style="index < 3 ? 'color: #83c32d ;' : 'color: #01aae0 ;'">
                                            <b style="font-size:12px;">
                                                <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-22px;' : 'margin-top:-20px'">
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Mayo</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Mayo</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                           {{ran.mayo}} 
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Junio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Junio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.junio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Julio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Julio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.julio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Agosto</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Agosto</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.agosto}}
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b>
                                        </div> 
                                        </td>
                                        <!-- <td>
                                            <h4 style="color:#fff;" class="mt-1">
                                                {{ran.puntos}}
                                            </h4>
                                        </td> -->
                                        <td>
                                            <div :style=" index < 3 ? 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0; margin-top:-4px;'
                                            : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0; margin-top:-4px' " :class="isMobile ? 'flexbox2' : 'flexbox'">
                                                <b style="font-size:26px;">
                                                    {{ran.total}}
                                                </b>
                                            </div>  
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </b-card-text>
                        <br v-if="!isMobile">
                    </b-card>
                    <hr><br>
                </template>
                <template>
                    <img src="https://inquebrantables2022.com.mx/campana/asistido.png" class="rounded" alt="Image" :style="!isMobile ? 'width:20%;' : 'width:50%;'">  
                    <b-card  style="background-color:#002860; border: 4px solid #368eff; border-radius: 10px; " class="mt-3">
                        <br v-if="!isMobile">
                        <b-card-text class="text-center">
                            <div class="table-responsive">
                                <table class="table table-borderless mt-3 text-center">
                                    <thead class="text-white">
                                    <tr>
                                           <td>LUGAR</td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td> 
                                        <td v-if="isMobile">GERENTE</td> 
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td><template v-if="!isMobile">GERENTE</template></td>
                                        <td>CALLCENTER</td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile">CUMPLIMIENTO</td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td><template v-if="!isMobile">CUMPLIMIENTO</template></td>
                                        <!-- <td>DESAFÍOS</td> -->
                                        <td>PUNTOS</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <br>
                                    <tr  v-for="(ran, index) in arrayAsistido" :key="ran.id"  >
                                        <td>
                                            <img v-if="index < 3" :src="'https://inquebrantables2022.com.mx/medalla/'+ index + '.png'" class="rounded" alt="Image" :style="!isMobile ? 'width:20%; margin-top:-8px;' : 'width:80%; margin-top:2px;'">   
                                      
                                             <br v-if="index < 3"> 
                                            <h4 v-if="index > 2" style="color: #01aae0 ;" class="mt-1">
                                            {{index + 1  }}
                                            </h4>  
                                            <br>
                                        </td>
                                        <td :colspan="isMobile ? '10' : ''">
                                            <div :style=" index < 3 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;' 
                                            : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                                 <b style=" font-size:13px; ">
                                                    {{ran.gerente}} 
                                                </b>
                                            </div>  
                                        </td>
                                        <td>
                                            <div :style=" index < 3 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;'
                                            : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                                 <b style=" font-size:13px; ">
                                                    {{ran.callcenter}}
                                                </b>
                                            </div>  
                                        </td>
                                        <td :colspan="isMobile ? '16' : ''">
                                                          <div :style="index < 3 ? 'color: #83c32d ;' : 'color: #01aae0 ;'">
                                            <b style="font-size:12px;">
                                                <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-22px;' : 'margin-top:-20px'">
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Mayo</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Mayo</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                           {{ran.mayo}} 
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Junio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Junio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.junio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Julio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Julio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.julio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Agosto</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Agosto</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.agosto}}
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b>
                                        </div> 
                                        </td>
                                        <!-- <td>
                                            <h4 style="color:#fff;" class="mt-1">
                                                {{ran.puntos}}
                                            </h4>
                                        </td> -->
                                        <td>
                                            <div :style=" index < 3 ? 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0; margin-top:-4px;'
                                            : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0; margin-top:-4px' " :class="isMobile ? 'flexbox2' : 'flexbox'">
                                                <b style="font-size:26px;">
                                                    {{ran.total}}
                                                </b>
                                            </div>  
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </b-card-text>
                        <br v-if="!isMobile">
                    </b-card>
                    <hr><br>
                </template>
                <template>
                    <img src="https://inquebrantables2022.com.mx/campana/migraciones.png" class="rounded" alt="Image" :style="!isMobile ? 'width:20%;' : 'width:50%;'">  
                    <b-card  style="background-color:#002860; border: 4px solid #368eff; border-radius: 10px; " class="mt-3">
                        <br v-if="!isMobile">
                        <b-card-text class="text-center">
                            <div class="table-responsive">
                                <table class="table table-borderless mt-3 text-center">
                                    <thead class="text-white">
                                    <tr>
                                           <td>LUGAR</td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td> 
                                        <td v-if="isMobile">GERENTE</td> 
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td><template v-if="!isMobile">GERENTE</template></td>
                                        <td>CALLCENTER</td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile">CUMPLIMIENTO</td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td><template v-if="!isMobile">CUMPLIMIENTO</template></td>
                                        <!-- <td>DESAFÍOS</td> -->
                                        <td>PUNTOS</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <br>
                                    <tr  v-for="(ran, index) in arrayMigraciones" :key="ran.id" >
                                        <td>
                                            <img v-if="index < 3" :src="'https://inquebrantables2022.com.mx/medalla/'+ index + '.png'" class="rounded" alt="Image" :style="!isMobile ? 'width:20%; margin-top:-8px;' : 'width:80%; margin-top:2px;'">  
                                    
                                             <br v-if="index < 3"> 
                                            <h4 v-if="index > 2" style="color: #01aae0 ;" class="mt-1">
                                            {{index + 1  }}
                                            </h4>  
                                            <br>
                                        </td>
                                        <td :colspan="isMobile ? '10' : ''">
                                            <div :style=" index < 3 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;' 
                                            : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                                 <b style=" font-size:13px; ">
                                                    {{ran.gerente}} 
                                                </b>
                                            </div>  
                                        </td>
                                        <td>
                                            <div :style=" index < 3 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;'
                                            : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                                 <b style=" font-size:13px; ">
                                                    {{ran.callcenter}}
                                                </b>
                                            </div>  
                                        </td>
                                        <td :colspan="isMobile ? '16' : ''">
                                                        <div :style="index < 3 ? 'color: #83c32d ;' : 'color: #01aae0 ;'">
                                            <b style="font-size:12px;">
                                                <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-22px;' : 'margin-top:-20px'">
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Mayo</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Mayo</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                           {{ran.mayo}} 
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Junio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Junio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.junio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Julio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Julio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.julio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Agosto</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Agosto</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.agosto}}
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b>
                                        </div> 
                                        </td>
                                        <!-- <td>
                                            <h4 style="color:#fff;" class="mt-1">
                                                {{ran.puntos}}
                                            </h4>
                                        </td> -->
                                        <td>
                                            <div :style=" index < 3 ? 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0; margin-top:-4px;'
                                            : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0; margin-top:-4px' " :class="isMobile ? 'flexbox2' : 'flexbox'">
                                                <b style="font-size:26px;">
                                                    {{ran.total}}
                                                </b>
                                            </div>  
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </b-card-text>
                        <br v-if="!isMobile">
                    </b-card>
                    <hr><br>
                </template>
                <template>
                    <img src="https://inquebrantables2022.com.mx/campana/upsell.png" class="rounded" alt="Image" :style="!isMobile ? 'width:20%;' : 'width:50%;'">  
                    <b-card  style="background-color:#002860; border: 4px solid #368eff; border-radius: 10px; " class="mt-3">
                        <br v-if="!isMobile">
                        <b-card-text class="text-center">
                            <div class="table-responsive">
                                <table class="table table-borderless mt-3 text-center">
                                    <thead class="text-white">
                                    <tr>
                                           <td>LUGAR</td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td> 
                                        <td v-if="isMobile">GERENTE</td> 
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td><template v-if="!isMobile">GERENTE</template></td>
                                        <td>CALLCENTER</td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile">CUMPLIMIENTO</td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td><template v-if="!isMobile">CUMPLIMIENTO</template></td>
                                        <!-- <td>DESAFÍOS</td> -->
                                        <td>PUNTOS</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <br>
                                    <tr  v-for="(ran, index) in arrayUpsell" :key="ran.id" >
                                        <td>
                                            <img v-if="index < 3" :src="'https://inquebrantables2022.com.mx/medalla/'+ index + '.png'" class="rounded" alt="Image" :style="!isMobile ? 'width:20%; margin-top:-8px;' : 'width:80%; margin-top:2px;'">  
                                            
                                             <br v-if="index < 3"> 
                                            <h4 v-if="index > 2" style="color: #01aae0 ;" class="mt-1">
                                            {{index + 1  }}
                                            </h4>  
                                            <br>
                                        </td>
                                        <td :colspan="isMobile ? '10' : ''">
                                            <div :style=" index < 3 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;' 
                                            : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                                 <b style=" font-size:13px; ">
                                                    {{ran.gerente}} 
                                                </b>
                                            </div>  
                                        </td>
                                        <td>
                                            <div :style=" index < 3 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;'
                                            : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                                 <b style=" font-size:13px; ">
                                                    {{ran.callcenter}}
                                                </b>
                                            </div>  
                                        </td>
                                        <td :colspan="isMobile ? '16' : ''">
                                                          <div :style="index < 3 ? 'color: #83c32d ;' : 'color: #01aae0 ;'">
                                            <b style="font-size:12px;">
                                                <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-22px;' : 'margin-top:-20px'">
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Mayo</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Mayo</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                           {{ran.mayo}} 
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Junio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Junio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.junio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Julio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Julio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.julio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Agosto</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Agosto</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.agosto}}
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b>
                                        </div> 
                                        </td>
                                        <td>
                                            <div :style=" index < 3 ? 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0; margin-top:-4px;'
                                            : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0; margin-top:-4px' " :class="isMobile ? 'flexbox2' : 'flexbox'">
                                                <b style="font-size:26px;">
                                                    {{ran.total}}
                                                </b>
                                            </div>  
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </b-card-text>
                        <br v-if="!isMobile">
                    </b-card>
                    <hr><br>
                </template>
                <template>
                    <img src="https://inquebrantables2022.com.mx/campana/renovaciones.png" class="rounded" alt="Image" :style="!isMobile ? 'width:20%;' : 'width:50%;'">  
                    <b-card  style="background-color:#002860; border: 4px solid #368eff; border-radius: 10px; " class="mt-3">
                        <br v-if="!isMobile">
                        <b-card-text class="text-center">
                            <div class="table-responsive">
                                <table class="table table-borderless mt-3 text-center">
                                    <thead class="text-white">
                                    <tr>
                                           <td>LUGAR</td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td> 
                                        <td v-if="isMobile">GERENTE</td> 
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td><template v-if="!isMobile">GERENTE</template></td>
                                        <td>CALLCENTER</td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile">CUMPLIMIENTO</td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td><template v-if="!isMobile">CUMPLIMIENTO</template></td>
                                        <!-- <td>DESAFÍOS</td> -->
                                        <td>PUNTOS</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <br>
                                    <tr  v-for="(ran, index) in arrayRenovaciones" :key="ran.id" >
                                        <td>
                                            <img v-if="index < 3" :src="'https://inquebrantables2022.com.mx/medalla/'+ index + '.png'" class="rounded" alt="Image" :style="!isMobile ? 'width:20%; margin-top:-8px;' : 'width:80%; margin-top:2px;'"> 
                                            
                                             <br v-if="index < 3"> 
                                            <h4 v-if="index > 2" style="color: #01aae0 ;" class="mt-1">
                                            {{index + 1  }}
                                            </h4>  
                                            <br>
                                        </td>
                                        <td :colspan="isMobile ? '10' : ''">
                                            <div :style=" index < 3 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;' 
                                            : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                                 <b style=" font-size:13px; ">
                                                    {{ran.gerente}} 
                                                </b>
                                            </div>  
                                        </td>
                                        <td>
                                            <div :style=" index < 3 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;'
                                            : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                                 <b style=" font-size:13px; ">
                                                    {{ran.callcenter}}
                                                </b>
                                            </div>  
                                        </td>
                                        <td :colspan="isMobile ? '16' : ''">
                                                          <div :style="index < 3 ? 'color: #83c32d ;' : 'color: #01aae0 ;'">
                                            <b style="font-size:12px;">
                                                <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-22px;' : 'margin-top:-20px'">
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Mayo</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Mayo</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                           {{ran.mayo}} 
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Junio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Junio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.junio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Julio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Julio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.julio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Agosto</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Agosto</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.agosto}}
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b>
                                        </div> 
                                        </td>
                                        <td>
                                            <div :style=" index < 3 ? 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0; margin-top:-4px;'
                                            : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0; margin-top:-4px' " :class="isMobile ? 'flexbox2' : 'flexbox'">
                                                <b style="font-size:26px;">
                                                    {{ran.total}}
                                                </b>
                                            </div>  
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </b-card-text>
                        <br v-if="!isMobile">
                    </b-card>
                    <hr><br>
                </template>
            </template>
            <template v-else>
                <template >
                    <img src="https://inquebrantables2022.com.mx/campana/prepago.png" class="rounded" alt="Image" :style="!isMobile ? 'width:20%;' : 'width:50%;'">  
                    <b-card  style="background-color:#002860; border: 4px solid #368eff; border-radius: 10px; " class="mt-3">
                        <br v-if="!isMobile">
                        <b-card-text class="text-center">
                            <div class="table-responsive">
                                <table class="table table-borderless mt-3 text-center">
                                    <thead class="text-white">
                                    <tr>
                                        <td>LUGAR</td>
                                        <td>CALLCENTER</td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile">CUMPLIMIENTO</td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td><template v-if="!isMobile">CUMPLIMIENTO</template></td>
                                        <td>PUNTOS</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <br>
                                    <tr  v-for="(ran, index) in arrayPrepago2" :key="ran.id"  >
                                        <td>
                                            <img v-if="index < 3" :src="'https://inquebrantables2022.com.mx/medalla/'+ index + '.png'" class="rounded" alt="Image" :style="!isMobile ? 'width:14%; margin-top:-5px;' : 'width:80%; margin-top:2px;'">   
                                             <br v-if="index < 3"> 
                                            <h4 v-if="index > 2" style="color: #01aae0 ;" class="mt-1">
                                            {{index + 1  }}
                                            </h4>  
                                            <br>
                                        </td>
                                        <td>
                                            <div :style=" index < 3 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;'
                                            : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                                <b style=" font-size:13px; ">
                                                    {{ran.callcenter}}
                                                </b>
                                            </div>  
                                        </td>
                                        <td :colspan="isMobile ? '16' : ''">
                                                          <div :style="index < 3 ? 'color: #83c32d ;' : 'color: #01aae0 ;'">
                                            <b style="font-size:12px;">
                                                <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-22px;' : 'margin-top:-20px'">
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Mayo</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Mayo</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                           {{ran.mayo}} 
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Junio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Junio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.junio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Julio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Julio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.julio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Agosto</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Agosto</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.agosto}}
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b>
                                        </div> 
                                        </td>
                                        <td>
                                            <div :style=" index < 3 ? 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0; margin-top:-4px;'
                                            : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0; margin-top:-4px' " :class="isMobile ? 'flexbox2' : 'flexbox'">
                                                <b style="font-size:26px;">
                                                    {{ran.total}}
                                                </b>
                                            </div>  
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </b-card-text>
                        <br v-if="!isMobile">
                    </b-card>
                    <hr><br>
                </template>
                <template >
                    <img src="https://inquebrantables2022.com.mx/campana/pospago.png" class="rounded" alt="Image" :style="!isMobile ? 'width:20%;' : 'width:50%;'">  
                    <b-card  style="background-color:#002860; border: 4px solid #368eff; border-radius: 10px; " class="mt-3">
                        <br v-if="!isMobile">
                        <b-card-text class="text-center">
                            <div class="table-responsive">
                                <table class="table table-borderless mt-3 text-center">
                                    <thead class="text-white">
                                    <tr>
                                        <td>LUGAR</td>
                                        <td>CALLCENTER</td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile">CUMPLIMIENTO</td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td><template v-if="!isMobile">CUMPLIMIENTO</template></td>
                                        <td>PUNTOS</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <br>
                                    <tr  v-for="(ran, index) in arrayPospago2" :key="ran.id" >
                                        <td>
                                            <img v-if="index < 3" :src="'https://inquebrantables2022.com.mx/medalla/'+ index + '.png'" class="rounded" alt="Image" :style="!isMobile ? 'width:14%; margin-top:-5px;' : 'width:80%; margin-top:2px;'">   
                                             <br v-if="index < 3"> 
                                            <h4 v-if="index > 2" style="color: #01aae0 ;" class="mt-1">
                                            {{index + 1  }}
                                            </h4>  
                                            <br>
                                        </td>
                                        <td>
                                            <div :style=" index < 3 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;'
                                            : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                               <b style=" font-size:13px; ">
                                                    {{ran.callcenter}}
                                                </b>
                                            </div>  
                                        </td>
                                        <td :colspan="isMobile ? '16' : ''">
                                                          <div :style="index < 3 ? 'color: #83c32d ;' : 'color: #01aae0 ;'">
                                            <b style="font-size:12px;">
                                                <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-22px;' : 'margin-top:-20px'">
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Mayo</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Mayo</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                           {{ran.mayo}} 
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Junio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Junio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.junio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Julio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Julio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.julio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Agosto</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Agosto</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.agosto}}
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b>
                                        </div> 
                                        </td>
                                        <td>
                                            <div :style=" index < 3 ? 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0; margin-top:-4px;'
                                            : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0; margin-top:-4px' " :class="isMobile ? 'flexbox2' : 'flexbox'">
                                                <b style="font-size:26px;">
                                                    {{ran.total}}
                                                </b>
                                            </div>  
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </b-card-text>
                        <br v-if="!isMobile">
                    </b-card>
                    <hr><br>
                </template>
                <template >
                    <img src="https://inquebrantables2022.com.mx/campana/asistido.png" class="rounded" alt="Image" :style="!isMobile ? 'width:20%;' : 'width:50%;'">  
                    <b-card  style="background-color:#002860; border: 4px solid #368eff; border-radius: 10px; " class="mt-3">
                        <br v-if="!isMobile">
                        <b-card-text class="text-center">
                            <div class="table-responsive">
                                <table class="table table-borderless mt-3 text-center">
                                    <thead class="text-white">
                                    <tr>
                                        <td>LUGAR</td>
                                        <td>CALLCENTER</td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile">CUMPLIMIENTO</td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td><template v-if="!isMobile">CUMPLIMIENTO</template></td>
                                        <td>PUNTOS</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <br>
                                    <tr  v-for="(ran, index) in arrayAsistido2" :key="ran.id">
                                        <td>
                                            <img v-if="index < 3" :src="'https://inquebrantables2022.com.mx/medalla/'+ index + '.png'" class="rounded" alt="Image" :style="!isMobile ? 'width:14%; margin-top:-5px;' : 'width:80%; margin-top:2px;'">   
                                             <br v-if="index < 3"> 
                                            <h4 v-if="index > 2" style="color: #01aae0 ;" class="mt-1">
                                            {{index + 1  }}
                                            </h4>  
                                            <br>
                                        </td>
                                        <td>
                                            <div :style=" index < 3 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;'
                                            : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                                <b style=" font-size:13px; ">
                                                    {{ran.callcenter}}
                                                </b>
                                            </div>  
                                        </td>
                                        <td :colspan="isMobile ? '16' : ''"> 
                                                        <div :style="index < 3 ? 'color: #83c32d ;' : 'color: #01aae0 ;'">
                                            <b style="font-size:12px;">
                                                <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-22px;' : 'margin-top:-20px'">
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Mayo</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Mayo</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                           {{ran.mayo}} 
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Junio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Junio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.junio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Julio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Julio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.julio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Agosto</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Agosto</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.agosto}}
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b>
                                        </div> 
                                        </td>
                                        <td>
                                            <div :style=" index < 3 ? 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0; margin-top:-4px;'
                                            : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0; margin-top:-4px' " :class="isMobile ? 'flexbox2' : 'flexbox'">
                                                <b style="font-size:26px;">
                                                    {{ran.total}}
                                                </b>
                                            </div>  
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </b-card-text>
                        <br v-if="!isMobile">
                    </b-card>
                    <hr><br>
                </template>
                <template>
                    <img src="https://inquebrantables2022.com.mx/campana/migraciones.png" class="rounded" alt="Image" :style="!isMobile ? 'width:20%;' : 'width:50%;'">  
                    <b-card  style="background-color:#002860; border: 4px solid #368eff; border-radius: 10px; " class="mt-3">
                        <br v-if="!isMobile">
                        <b-card-text class="text-center">
                            <div class="table-responsive">
                                <table class="table table-borderless mt-3 text-center">
                                    <thead class="text-white">
                                    <tr>
                                        <td>LUGAR</td>
                                        <td>CALLCENTER</td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile">CUMPLIMIENTO</td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td><template v-if="!isMobile">CUMPLIMIENTO</template></td>
                                        <td>PUNTOS</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <br>
                                    <tr  v-for="(ran, index) in arrayMigraciones2" :key="ran.id">
                                        <td>
                                            <img v-if="index < 3" :src="'https://inquebrantables2022.com.mx/medalla/'+ index + '.png'" class="rounded" alt="Image" :style="!isMobile ? 'width:14%; margin-top:-5px;' : 'width:80%; margin-top:2px;'">     
                                             <br v-if="index < 3"> 
                                            <h4 v-if="index > 2" style="color: #01aae0 ;" class="mt-1">
                                            {{index + 1  }}
                                            </h4>  
                                            <br>
                                        </td>
                                        <td>
                                            <div :style=" index < 3 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;'
                                            : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                                <b style=" font-size:13px; ">
                                                    {{ran.callcenter}}
                                                </b>
                                            </div>  
                                        </td>
                                        <td :colspan="isMobile ? '16' : ''">
                                                           <div :style="index < 3 ? 'color: #83c32d ;' : 'color: #01aae0 ;'">
                                            <b style="font-size:12px;">
                                                <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-22px;' : 'margin-top:-20px'">
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Mayo</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Mayo</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                           {{ran.mayo}} 
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Junio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Junio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.junio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Julio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Julio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.julio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Agosto</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Agosto</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.agosto}}
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b>
                                        </div> 
                                        </td>
                                        <td>
                                            <div :style=" index < 3 ? 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0; margin-top:-4px;'
                                            : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0; margin-top:-4px' " :class="isMobile ? 'flexbox2' : 'flexbox'">
                                                <b style="font-size:26px;">
                                                    {{ran.total}}
                                                </b>
                                            </div>  
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </b-card-text>
                        <br v-if="!isMobile">
                    </b-card>
                    <hr><br>
                </template>
                <template >
                    <img src="https://inquebrantables2022.com.mx/campana/upsell.png" class="rounded" alt="Image" :style="!isMobile ? 'width:20%;' : 'width:50%;'">  
                    <b-card  style="background-color:#002860; border: 4px solid #368eff; border-radius: 10px; " class="mt-3">
                        <br v-if="!isMobile">
                        <b-card-text class="text-center">
                            <div class="table-responsive">
                                <table class="table table-borderless mt-3 text-center">
                                    <thead class="text-white">
                                    <tr>
                                        <td>LUGAR</td>
                                        <td>CALLCENTER</td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile">CUMPLIMIENTO</td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td><template v-if="!isMobile">CUMPLIMIENTO</template></td>
                                        <td>PUNTOS</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <br>
                                    <tr  v-for="(ran, index) in arrayUpsell2" :key="ran.id" >
                                        <td>
                                            <img v-if="index < 3" :src="'https://inquebrantables2022.com.mx/medalla/'+ index + '.png'" class="rounded" alt="Image" :style="!isMobile ? 'width:14%; margin-top:-5px;' : 'width:80%; margin-top:2px;'">     
                                            <h4 v-if="index > 2" style="color: #5ea7dd ;" class="mt-1">
                                            {{index + 1  }}
                                            </h4>  
                                            <br>
                                        </td>
                                        <td>
                                            <div :style=" index < 3 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;'
                                            : 'background-color: #5ea7dd; border-radius: 10px; color:#000; border-color:#5ea7dd;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                                <b style=" font-size:13px; ">
                                                    {{ran.callcenter}}
                                                </b>
                                            </div>  
                                        </td>
                                        <td :colspan="isMobile ? '16' : ''">
                                                           <div :style="index < 3 ? 'color: #83c32d ;' : 'color: #01aae0 ;'">
                                            <b style="font-size:12px;">
                                                <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-22px;' : 'margin-top:-20px'">
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Mayo</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Mayo</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                           {{ran.mayo}} 
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Junio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Junio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.junio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Julio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Julio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.julio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Agosto</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Agosto</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.agosto}}
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b>
                                        </div> 
                                        </td>
                                        <td>
                                            <div :style=" index < 3 ? 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0; margin-top:-4px;'
                                            : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0; margin-top:-4px' " :class="isMobile ? 'flexbox2' : 'flexbox'">
                                                <b style="font-size:26px;">
                                                    {{ran.total}}
                                                </b>
                                            </div>  
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </b-card-text>
                        <br v-if="!isMobile">
                    </b-card>
                    <hr><br>
                </template>
                <template>
                    <img src="https://inquebrantables2022.com.mx/campana/renovaciones.png" class="rounded" alt="Image" :style="!isMobile ? 'width:20%;' : 'width:50%;'">  
                    <b-card  style="background-color:#002860; border: 4px solid #368eff; border-radius: 10px; " class="mt-3">
                        <br v-if="!isMobile">
                        <b-card-text class="text-center">
                            <div class="table-responsive">
                                <table class="table table-borderless mt-3 text-center">
                                    <thead class="text-white">
                                    <tr>
                                        <td>LUGAR</td>
                                        <td>CALLCENTER</td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile">CUMPLIMIENTO</td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td><template v-if="!isMobile">CUMPLIMIENTO</template></td>
                                        <td>PUNTOS</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <br>
                                    <tr  v-for="(ran, index) in arrayRenovaciones2" :key="ran.id">
                                        <td>
                                            <img v-if="index < 3" :src="'https://inquebrantables2022.com.mx/medalla/'+ index + '.png'" class="rounded" alt="Image" :style="!isMobile ? 'width:14%; margin-top:-5px;' : 'width:80%; margin-top:2px;'">     
                                            <h4 v-if="index > 2" style="color: #5ea7dd ;" class="mt-1">
                                            {{index + 1  }}
                                            </h4>  
                                            <br>
                                        </td>
                                        <td>
                                            <div :style=" index < 3 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;'
                                            : 'background-color: #5ea7dd; border-radius: 10px; color:#000; border-color:#5ea7dd;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                                <b style=" font-size:13px; ">
                                                    {{ran.callcenter}}
                                                </b>
                                            </div>  
                                        </td>
                                        <td :colspan="isMobile ? '16' : ''">
                                                           <div :style="index < 3 ? 'color: #83c32d ;' : 'color: #01aae0 ;'">
                                            <b style="font-size:12px;">
                                                <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-22px;' : 'margin-top:-20px'">
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Mayo</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Mayo</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                           {{ran.mayo}} 
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Junio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Junio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.junio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Julio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Julio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.julio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Agosto</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Agosto</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.agosto}}
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b>
                                        </div> 
                                        </td>
                                        <td>
                                            <div :style=" index < 3 ? 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0; margin-top:-4px;'
                                            : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0; margin-top:-4px' " :class="isMobile ? 'flexbox2' : 'flexbox'">
                                                <b style="font-size:26px;">
                                                    {{ran.total}}
                                                </b>
                                            </div>  
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </b-card-text>
                        <br v-if="!isMobile">
                    </b-card>
                    <hr><br>
                </template>         
            </template>
        </b-col>
     </b-row>
  </div>
</template>

<script>
function compare(a, b) {
  if (a.total > b.total) {
    return -1;
  }
  if (a.total < b.total) {
    return 1;
  }
  return 0;
}

import SpinnerLoader from "../../utils/SpinnerLoader.vue";
export default {
  name: 'Ranking',
  components:{
    SpinnerLoader
  },
  data(){
    return{
      isMobile: this.$mobile(),
      loader:false,
      arrayRanking:[],
      arrayRanking2:[],
      tab:0,
      bases:{},
      arrayCamp:['prepago','pospago','asistido','migraciones','upsell','renovaciones'],
      colors:['#83c32d','#83c32d','#83c32d','#5ea7dd','#5ea7dd'],
      arrayPrepago:[],
      arrayPospago:[],
      arrayAsistido:[],
      arrayMigraciones:[],
      arrayUpsell:[],
      arrayRenovaciones:[],
      arrayPrepago2:[],
      arrayPospago2:[],
      arrayAsistido2:[],
      arrayMigraciones2:[],
      arrayUpsell2:[],
      arrayRenovaciones2:[]
    }
  },
  methods:{
    getcampanas(){
      this.loader = true;
      var url= 'auth/rankintlmkt';
        this.$api.get(url).then(
            ({data}) => {
                console.log(data);
                this.bases = data.base;
                this.arrayPrepago = data.prepago.sort(compare);
                this.arrayPospago = data.pospago.sort(compare);
                this.arrayAsistido = data.asistido.sort(compare);
                this.arrayMigraciones = data.migraciones.sort(compare);
                this.arrayUpsell = data.upsell.sort(compare);
                this.arrayRenovaciones = data.renovaciones.sort(compare);
                this.arrayPrepago2 = data.prepago2.sort(compare);
                this.arrayPospago2 = data.pospago2.sort(compare);
                this.arrayAsistido2 = data.asistido2.sort(compare);
                this.arrayMigraciones2 = data.migraciones2.sort(compare);
                this.arrayUpsell2 = data.upsell2.sort(compare);
                this.arrayRenovaciones2 = data.renovaciones2.sort(compare);
                this.loader = false;
            }
        );
    },
    regresarHome(){
        this.$bus.$emit("cambia_fondo2", true)
        this.$router.push("/embajador"); 
    }
  },
  mounted(){
    this.$bus.$emit("cambia_fondo", true)
    this.getcampanas();
  }
}
</script>
<style>
.flexbox {
  align-items: center;
  display: flex;
  height: 42px;
  justify-content: center;
  width: auto;
}

.flexbox2 {
  align-items: center;
  display: flex;
  height: 45px;
  justify-content: center;
  width: auto;
}
</style>
<template>
  <div>   
    <template v-if="!isMobile"><br><br> <br><br><br><br><br><br></template><br>
    <center>
      <img src="https://inquebrantables2022.com.mx/elementos/logo.png" class="rounded" alt="Image" :style="isMobile ? 'width:95%;' : ''">
      <br><br>
      <u class="mt-3" style="color:#5cb617; cursor: pointer;" @click="regresarHome()" v-if="canal == 2">Regresar</u>
    </center>
    <br><br>
    <template v-if="canal == 2">
      <tlmkt2></tlmkt2>
    </template>
    <template v-if="canal == 3">
      <propio2></propio2>
    </template>
   <template v-if="canal == 1">
      <retail2></retail2>
    </template>
  </div>
</template>

<script>
import propio2 from './Propio2.vue';
import tlmkt2 from './Tlmkt2.vue';
import retail2 from './Retail2.vue';
export default {
  name: 'Ranking',
  components:{
    tlmkt2,
    propio2,
    retail2
  },
  data(){
    return{
      isMobile: this.$mobile(),
      canal:0
    }
  },
  computed: {
    user() {
      return this.$store.getters.getInfoUser;
    }
  },
  methods:{
    regresarHome(){
        this.$bus.$emit("cambia_fondo2", true)
        this.$router.push("/embajador"); 
    }
  },
  mounted(){
    this.canal = this.$route.params.canal;
  }
}
</script>
<template>
  <div>   
    <br>
     <b-row class="justify-content-center">
        <b-col cols="11" md="11" class="text-center">
            <center>
                <v-tabs  fixed-tabs  background-color="transparent" dark >
                    <v-tab @click="tab = 0" style="font-size:17px;">
                       Nacional
                    </v-tab>
                    <v-tab  @click="tab = 2">
                      <template v-if="isMobile">Agencia</template><template v-else>Supervisor de Agencia</template>
                    </v-tab>
                    <v-tab  @click="tab = 3" style="font-size:17px;">
                      Lider
                    </v-tab>
                </v-tabs>
            </center>
            <br><br>
            <center v-if="loader">
                <SpinnerLoader/>
                <p  style="color:#fff;">Procesando información, no cierres el navegador <br> espera un momento...</p>
            </center>
            <br>
            <template v-if="tab == 0">
                <b-row class="justify-content-center">
                    <b-col cols="11" md="5"></b-col>
                    <b-col cols="8" md="2">
                    <input v-model="buscar_PDV" type="text" class="form-control" placeholder="Buscar IDPDV">
                    </b-col>
                    <b-col cols="2" md="5" class="text-left mt-2"> 
                        <u class="mt-4" style="color:#5cb617; cursor: pointer;" @click="regresarHome()">Regresar</u>
                    </b-col>
                </b-row>
                <br>
                 <b-card  style="background-color:#002860; border: 4px solid #368eff; border-radius: 10px; " class="mt-3">
                    <br v-if="!isMobile">
                    <b-card-text class="text-center">
                       <div class="table-responsive">
                         <table class="table table-borderless mt-3 text-center">
                            <thead class="text-white">
                            <tr>
                                <td>LUGAR</td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile">IDPDV</td>
                                <td :style="isMobile ? ' ' : 'width:10%;'"><template v-if="!isMobile">IDPDV</template></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile">TIENDA</td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td :style="isMobile ? ' ' : 'width:25%;'"><template v-if="!isMobile">TIENDA</template></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile">REGIÓN</td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td ><template v-if="!isMobile">REGIÓN</template></td>
                                <td>PLAZA</td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile">CUMPLIMIENTO</td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td  :style="isMobile ? '' : 'width:25%;'" class="text-center"><template v-if="!isMobile">CUMPLIMIENTO</template></td>
                                <!-- <td>DESAFÍOS</td> -->
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile">PUNTOS</td>
                                <td :style="isMobile ? ' ' : 'width:8%;'"><template v-if="!isMobile">PUNTOS</template></td>
                            </tr>
                            </thead>
                            <tbody>
                            <br>
                            <tr  v-for="(ran, index) in puntov" :key="ran.id + '-pdv'" >
                                <td>
                                 <template v-if="!buscar_PDV">
                                    <img v-if="index < 3" :src="'https://inquebrantables2022.com.mx/medalla/'+ index + '.png'" class="rounded" alt="Image" :style="!isMobile ? 'width:45%; margin-top:-8px;' : 'width:80%; margin-top:2px;'">   
                                    <br v-if="index < 3"> 
                                    <h4 v-if="index > 2" style="color: #01aae0 ;" :class="isMobile ? 'mt-4' : 'mt-1'">
                                      {{index + 1  }}
                                    </h4>  
                                    <br>
                                  </template>
                                  <template v-else>
                                   <img v-if="ran.lugar < 4" :src="'https://inquebrantables2022.com.mx/medalla/'+ (ran.lugar - 1) + '.png'" class="rounded" alt="Image" :style="!isMobile ? 'width:35%; margin-top:-5px;' : 'width:80%; margin-top:2px;'">   
                                        <br v-if="ran.lugar < 4"> 
                                        <h4 v-if="ran.lugar > 3" style="color: #01aae0 ;" :class="isMobile ? 'mt-2' : 'mt-1'">
                                        {{ran.lugar}}
                                        </h4>  
                                    <br>
                                  </template>
                                </td>
                                <td :colspan="isMobile ? '3' : ''">
                                    <div :style=" ran.lugar < 4 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;' 
                                    : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                        <b style="font-size:13px;">
                                            {{ran.idpdv}}
                                        </b>
                                    </div>  
                                </td>
                                <td :colspan="isMobile ? '10' : ''">
                                    <div :style=" ran.lugar < 4 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;'
                                     : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                        <b style="font-size:13px;">
                                            {{ran.tienda}}
                                        </b>
                                    </div>  
                                </td>
                                <td :colspan="isMobile ? '6' : ''">
                                    <div :style=" ran.lugar < 4 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;'
                                     : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                        <b style="font-size:13px;">
                                            {{ran.region}}
                                        </b>
                                    </div>  
                                </td>
                                <td>
                                    <div :style=" ran.lugar < 4 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;'
                                     : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                        <b style="font-size:13px;">
                                            {{ran.plaza}}
                                        </b>
                                    </div>  
                                </td>
                                <td :colspan="isMobile ? '16' : ''">
                                    <div :style="ran.lugar < 4 ? 'color: #83c32d ;' : 'color: #01aae0 ;'">
                                            <b style="font-size:12px;">
                                                <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-22px;' : 'margin-top:-20px'">
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Mayo</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Mayo</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                           {{ran.mayo}} 
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Junio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Junio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.junio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Julio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Julio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.julio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Agosto</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Agosto</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.agosto}}
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b>
                                        </div> 
                                </td>
                                <!-- <td>
                                    <h4 style="color: #fff;" class="mt-1">
                                        {{ran.puntos}}
                                    </h4>
                                </td> -->
                                <td :colspan="isMobile ? '3' : ''">
                                    <div :style=" ran.lugar < 4 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d; margin-top:-4px'
                                     : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0; margin-top:-4px' " :class="isMobile ? 'flexbox2' : 'flexbox'">
                                        <b style="font-size:26px;">
                                            {{ran.total}}
                                        </b>
                                    </div> 
                                </td>
                            </tr>
                            </tbody>
                        </table>
                       </div>
                    </b-card-text>
                    <br v-if="!isMobile">
                </b-card>
                <hr><br>        
            </template>
             <template v-if="tab == 2">
                <b-row class="justify-content-center">
                    <b-col cols="11" md="5"></b-col>
                    <b-col cols="8" md="2">
                    <input v-model="buscar_agente" type="text" class="form-control" placeholder="Buscar agente">
                    </b-col>
                    <b-col cols="2" md="5" class="text-left mt-2"> 
                        <u class="mt-4" style="color:#5cb617; cursor: pointer;" @click="regresarHome()">Regresar</u>
                    </b-col>
                </b-row>
                <br>
                <b-card  style="background-color:#002860; border: 4px solid #368eff; border-radius: 10px; " class="mt-3">
                    <br v-if="!isMobile">
                    <b-card-text class="text-center">
                       <div class="table-responsive">
                         <table class="table table-borderless mt-3 text-center">
                            <thead class="text-white">
                            <tr>
                                <td>LUGAR</td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile">AGENTE</td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td><template v-if="!isMobile">AGENTE</template></td>
                                 <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile">REGIÓN</td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td ><template v-if="!isMobile">REGIÓN</template></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile">CUMPLIMIENTO</td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td  :style="isMobile ? '' : 'width:25%;'" class="text-center"><template v-if="!isMobile">CUMPLIMIENTO</template></td>
                                <!-- <td>DESAFÍOS</td> -->
                                  <td v-if="isMobile"></td>
                                <td v-if="isMobile">PUNTOS</td>
                                <td :style="isMobile ? ' ' : 'width:8%;'"><template v-if="!isMobile">PUNTOS</template></td>
                            </tr>
                            </thead>
                            <tbody>
                            <br>
                            <tr  v-for="(ran, index) in Agentes" :key="ran.id + '-agente'">
                                <td>
                                 <template v-if="!buscar_agente">
                                    <img v-if="index < 3" :src="'https://inquebrantables2022.com.mx/medalla/'+ index + '.png'" class="rounded" alt="Image" :style="!isMobile ? 'width:25%; margin-top:-8px;' :  'width:80%; margin-top:2px;'">   
                                    <br v-if="index < 3"> 
                                    <h4 v-if="index > 2" style="color: #01aae0 ;" :class="isMobile ? 'mt-4' : 'mt-1'">
                                      {{index + 1  }}
                                    </h4>  
                                    <br>
                                  </template>
                                  <template v-else>
                                   <img v-if="ran.lugar < 4" :src="'https://inquebrantables2022.com.mx/medalla/'+ (ran.lugar - 1) + '.png'" class="rounded" alt="Image" :style="!isMobile ? 'width:20%; margin-top:-8px;' :  'width:80%; margin-top:2px;'">   
                                      <br v-if="ran.lugar < 4"> 
                                    <h4 v-if="ran.lugar > 3" style="color: #01aae0 ;" :class="isMobile ? 'mt-2' : 'mt-1'">
                                      {{ran.lugar}}
                                    </h4>  
                                    <br>
                                  </template>
                                </td>
                                <td :colspan="isMobile ? '12' : ''">
                                    <div :style="ran.lugar < 4? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;' 
                                    : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                        <b style="font-size:13px;">
                                            {{ran.agente}}
                                        </b>
                                    </div>  
                                </td>
                                <td :colspan="isMobile ? '6' : ''">
                                    <div :style="ran.lugar < 4? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;'
                                     : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                        <b style="font-size:13px;">
                                            {{ran.region}}
                                        </b>
                                    </div>  
                                </td>
                                <td :colspan="isMobile ? '16' : ''">
                                    <div :style="ran.lugar < 4 ? 'color: #83c32d ;' : 'color: #01aae0 ;'">
                                            <b style="font-size:12px;">
                                                <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-22px;' : 'margin-top:-20px'">
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Mayo</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Mayo</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                           {{ran.mayo}} 
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Junio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Junio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.junio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Julio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Julio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.julio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Agosto</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Agosto</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.agosto}}
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b>
                                        </div> 
                                </td>
                                <!-- <td>
                                    <h4 style="color: #fff;" class="mt-1">
                                        {{ran.puntos}}
                                    </h4>
                                </td> -->
                                <td :colspan="isMobile ? '3' : ''">
                                    <div :style=" ran.lugar < 4 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d; margin-top:-4px'
                                     : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0; margin-top:-4px' " :class="isMobile ? 'flexbox2' : 'flexbox'">
                                        <b style="font-size:26px;">
                                            {{ran.total}}
                                        </b>
                                    </div> 
                                </td>
                            </tr>
                            </tbody>
                        </table>
                       </div>
                    </b-card-text>
                    <br v-if="!isMobile">
                </b-card>
                <hr><br>
            </template>
            <template v-if="tab == 3">
                <b-row class="justify-content-center">
                    <b-col cols="11" md="5"></b-col>
                    <b-col cols="8" md="2">
                    <input v-model="buscar_lider" type="text" class="form-control" placeholder="Buscar líder">
                    </b-col>
                    <b-col cols="2" md="5" class="text-left mt-2"> 
                        <u class="mt-4" style="color:#5cb617; cursor: pointer;" @click="regresarHome()">Regresar</u>
                    </b-col>
                </b-row>
                <br>
                <b-card  style="background-color:#002860; border: 4px solid #368eff; border-radius: 10px; " class="mt-3">
                    <br v-if="!isMobile">
                    <b-card-text class="text-center">
                       <div class="table-responsive">
                         <table class="table table-borderless mt-3 text-center">
                            <thead class="text-white">
                            <tr>
                                <td>LUGAR</td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile">LÍDER</td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td><template v-if="!isMobile">LÍDER</template></td>
                                 <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile">REGIÓN</td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td ><template v-if="!isMobile">REGIÓN</template></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile">CUMPLIMIENTO</td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td  :style="isMobile ? '' : 'width:25%;'" class="text-center"><template v-if="!isMobile">CUMPLIMIENTO</template></td>
                                <td>PUNTOS</td>
                            </tr>
                            </thead>
                            <tbody>
                            <br>
                            <tr  v-for="(ran, index) in Lider" :key="ran.id + '-lider'">
                                <td>
                                 <template v-if="!buscar_lider">
                                    <img v-if="index < 3" :src="'https://inquebrantables2022.com.mx/medalla/'+ index + '.png'" class="rounded" alt="Image" :style="!isMobile ? 'width:22%; margin-top:-5px;' : 'width:80%; margin-top:2px;' ">   
                                    <br v-if="index < 3"> 
                                    <h4 v-if="index > 2" style="color: #01aae0 ;" :class="isMobile ? 'mt-4' : 'mt-1'">
                                      {{index + 1  }}
                                    </h4>  
                                    <br>
                                  </template>
                                  <template v-else>
                                   <img v-if="ran.lugar < 4" :src="'https://inquebrantables2022.com.mx/medalla/'+ (ran.lugar - 1) + '.png'" class="rounded" alt="Image" :style="!isMobile ? 'width:20%; margin-top:-8px;' : 'width:80%; margin-top:2px;' ">   
                                    <br v-if="ran.lugar < 4"> 
                                    <h4 v-if="ran.lugar > 3" style="color: #01aae0 ;" :class="isMobile ? 'mt-2' : 'mt-1'">
                                      {{ran.lugar}}
                                    </h4>  
                                    <br>
                                  </template>
                                </td>
                                <td :colspan="isMobile ? '12' : ''">
                                    <div :style=" ran.lugar < 4 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;' 
                                    : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                        <b style="font-size:13px;">
                                            {{ran.lider}} 
                                        </b>
                                    </div>  
                                </td>
                                <td :colspan="isMobile ? '6' : ''">
                                    <div :style=" ran.lugar < 4 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;'
                                     : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0;'" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                        <b style="font-size:13px;">
                                            {{ran.region}}
                                        </b>
                                    </div>  
                                </td>
                                <td :colspan="isMobile ? '16' : ''">
                                       <div :style="ran.lugar < 4 ? 'color: #83c32d ;' : 'color: #01aae0 ;'">
                                            <b style="font-size:12px;">
                                                <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-22px;' : 'margin-top:-20px'">
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Mayo</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Mayo</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                           {{ran.mayo}} 
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Junio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Junio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.junio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Julio</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Julio</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.julio}}
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><span style="text-transform:capitalize; font-size:15px;">Agosto</span><br></template>
                                                        <template v-else><span style="text-transform:capitalize; font-size:15px;" v-if="index == 0">Agosto</span><br></template>   
                                                        <h4 style="color: #fff;" class="mt-2">
                                                            {{ran.agosto}}
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b>
                                        </div> 
                                </td>
                                <!-- <td>
                                    <h4 style="color: #fff;" class="mt-1">
                                        {{ran.puntos}}
                                    </h4>
                                </td> -->
                                <td>
                                    <div :style=" ran.lugar < 4 ? 'background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d; margin-top:-4px'
                                     : 'background-color: #01aae0; border-radius: 10px; color:#000; border-color:#01aae0; margin-top:-4px' " :class="isMobile ? 'flexbox2' : 'flexbox'">
                                        <b style="font-size:26px;">
                                            {{ran.total}}
                                        </b>
                                    </div> 
                                </td>
                            </tr>
                            </tbody>
                        </table>
                       </div>
                    </b-card-text>
                    <br v-if="!isMobile">
                </b-card>
                <hr><br>
            </template>
        </b-col>
     </b-row>
  </div>
</template>

<script>
function compare(a, b) {
  if (a.total > b.total) {
    return -1;
  }
  if (a.total < b.total) {
    return 1;
  }
  return 0;
}

import SpinnerLoader from "../../utils/SpinnerLoader.vue";
export default {
  name: 'Propio',
  components:{
    SpinnerLoader
  },
  data(){
    return{
      isMobile: this.$mobile(),
      loader:false,
      arrayLider:[],
      arrayPdv:[],
      arrayAgente:[],
      tab:0,
      colors:'#83c32d',
      buscar_lider:"",
      buscar_agente:"",
      buscar_PDV:""
    }
  },
  computed: {
    Agentes(){
        if (this.buscar_agente == '') {          
                return this.arrayAgente;
            }else{
                return   this.arrayAgente.filter(item => {
                        return   item.agente?.toLowerCase().includes(this.buscar_agente.toLowerCase())
                        ;
                });
            }
    },
    Lider(){
        if (this.buscar_lider == '') {          
                return this.arrayLider;
            }else{
                return   this.arrayLider.filter(item => {
                        return   item.lider?.toLowerCase().includes(this.buscar_lider.toLowerCase())
                        ;
                });
            }
    },
    puntov(){
     if (this.buscar_PDV == '') {          
            return this.arrayPdv;
        }else{
               return   this.arrayPdv.filter(item => {
                    return   item.idpdv?.toLowerCase().includes(this.buscar_PDV.toLowerCase())
                    ;
              });
        }
    }
  },
  methods:{
    getcampanas(){
      this.loader = true;
      var url= 'auth/rankinretail';
        this.$api.get(url).then(
            ({data}) => {
                console.log(data);
                this.arrayLider = data.lider.sort(compare);
                this.arrayAgente = data.agente.sort(compare);
                this.arrayPdv = data.pdv.sort(compare);
                this.loader = false;
            }
        );
    },
    regresarHome(){
        this.$bus.$emit("cambia_fondo2", true)
        this.$router.push("/embajador"); 
    }
  },
  mounted(){
    this.$bus.$emit("cambia_fondo", true)
    this.getcampanas();
  }
}
</script>
<style>
.flexbox {
  align-items: center;
  display: flex;
  height: 42px;
  justify-content: center;
  width: auto;
}

.flexbox2 {
  align-items: center;
  display: flex;
  height: 45px;
  justify-content: center;
  width: auto;
}
</style>